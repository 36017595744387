export default [
  {
    title: 'Easy carta porte resolvió mis problemas de traslado de mercancía',
    content:
      'Probé otros sistemas y no fueron de mi agrado',
    customerName: 'Arminda Martínez',
    customerTitle: 'Director de fletes nasdaq',
    customerImage: 'https://placeimg.com/150/150/people'
  },
  {
    title: 'Easy carta porte resolvió mis problemas de traslado de mercancía',
    content:
      'Probé otros sistemas y no fueron de mi agrado',
    customerName: 'María Mauro',
    customerTitle: 'Director de fletes nasdaq',
    customerImage: 'https://placeimg.com/150/150/people'
  },
  {
    title: 'El sistema cumplió con mis expectativas',
    content:
      'Probé otros sistemas y no fueron de mi agrado',
    customerName: 'Diana López',
    customerTitle: 'Director de fletes nasdaq',
    customerImage: 'https://placeimg.com/150/150/people'
  }
];
