import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
//import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
//import HeroImage from '../svg/HeroImage';
//import SvgCharts from '../svg/SvgCharts';
import { StaticImage } from "gatsby-plugin-image";
import ContactForm from '../components/ContactForm';

export function Dashboard() {
  return (
    <StaticImage
      src="../images/Dashboard.PNG"
      alt="Carta Porte"
      layout="fixed"
      //width={200}
      height={320} />
  )
}

const abrirPortal = () => {
  console.log("abrirPortal");
  window.open("http://portal.easycartaporte.com.mx:2083/", '_blank');
};

const Index = () => (
  <Layout>
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Easy Carta Porte
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Genera tus comprobantes fiscales de carta porte 2.0 con nuestro sistema certificado por el sat.
          </p>
          <p className="mt-8 md:mt-12">
            <Button size="lg" onClick={abrirPortal}>Comenzar</Button>
          </p>
        </div>
        <div className="lg:w-1/2">
          <Dashboard />
        </div>
      </div>
    </section>
    <section id="features" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Funciones</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Generación de Carta Porte de ingresos</p>
              <p className="mt-4">
                Genera un comprobante fiscal con complemento Carta Porte por las operaciones de traslado
                o por los servicios de transporte de bienes o mercancías, que se realicen
                dentro del territorio nacional.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Generación de Carta Porte de traslados</p>
              <p className="mt-4">
                Genera un comprobante fiscal con complemento Carta Porte por las operaciones de traslado
                o por los servicios de transporte de bienes o mercancías, que se realicen
                dentro del territorio nacional.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Catálogos</p>
              <p className="mt-4">
                Administra tu catálogo de:
                <ul>
                  <li>vehiculos</li>
                  <li>clientes</li>
                  <li>empleados</li>
                  <li>remolques</li>
                  <li>destinatarios</li>
                </ul>
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>
    {/*<SplitSection
      id="services"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Market Analysis</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Our team of enthusiastic marketers will analyse and evaluate how your company stacks
            against the closest competitors
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Design And Plan Your Business Growth Steps
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Once the market analysis process is completed our staff will search for opportunities
            that are in reach
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Search For Performance Optimization
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            With all the information in place you will be presented with an action plan that your
            company needs to follow
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />*/}
    <section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Nuestros clientes obtienen los siguientes beneficios</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="5x" secondaryText="Más rápido generación de Carta Porte que la competencia" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Facturas emitidas" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="Fácil" secondaryText="Poco tiempo para aprender el uso de sistema" />
          </div>
        </div>
      </div>
    </section>
    <section id="testimonials" className="py-20 lg:py-40">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">¿Qué opinan nuestros clientes?</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map(customer => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section>
    <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">¿Listo para hacer crecer su negocio?</h3>
      <p className="mt-8 text-xl font-light">
        Cumple con las obligaciones del SAT de una manera rápida, sencilla y confiable
      </p>
      <p className="mt-8">
        {/*<Button size="xl">Quiero comenzar ya</Button>*/}
        <ContactForm />
      </p>
    </section>
  </Layout>
);

export default Index;
